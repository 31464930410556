import React from "react"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import { Form } from "skillstrainer-resource-library"
import JoinOurCommunityFormBuilder from "../formBuilder/join-our-community-form-builder"

export default function UserInterest() {
  const handleSubmit = async register => {
    let payload
    console.log("register===", register)

    payload = {
      data: {
        entity: {
          type: "user-interest",
          eventId: 1,
        },
        operation: "insert",

        data: {
          form_type: register.form_type,
          full_name: register.full_name,
          designation: register.designation,
          company_name: register.company_name,
          email: register.email,
          phone_number: register.phone_number,
          country: register.country,
          bio_or_website_link: register.bio_or_website_link,
          resume: register.resume[0]?.url,
          areas_of_interest_for_individual:
            register.areas_of_interest_for_individual,
          areas_of_interest_for_organisation:
            register.areas_of_interest_for_organisation,
          organisation_category: register.organisation_category,
          required_solutions: register.required_solutions,
          country: "not required",
        },
      },
    }
    try {
      const result = await axios.post(
        `${process.env.URL}/api/user-interests`,
        payload
      )

      if (result.status === 200) {
        return toast.success("Success", {
          position: toast.POSITION.TOP_CENTER,
        })
      } else {
        toast.error("Something gone wrong!", {
          position: toast.POSITION.TOP_CENTER,
        })
      }
    } catch (err) {
      console.log("I am error", err)
      return toast.error("Something gone wrong!", {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }

  return (
    <>
      <div className="mt-5 pt-3 px-5">
        <h1 className="heading mb-3">Join Indian ESG NETWORK</h1>
        <ToastContainer />

        <Form
          formBuilder={JoinOurCommunityFormBuilder}
          className="ml-6 mt-2 grid grid-cols-2 gap-x-2"
          submitButton={{
            text: "Save",
            className: "btn learn-more-btn",
          }}
          plugins={{
            file: {
              services: {
                uploadFn: async fileData => {
                  // * strapi upload logic
                  const formData = new FormData()
                  formData.append("files", fileData)
                  return await axios
                    .post(`${process.env.URL}/api/upload`, formData)
                    .then(({ data }) => {
                      return data[0].id
                    })
                },
              },
            },
          }}
          onSubmit={handleSubmit}
        />
      </div>
    </>
  )
}
