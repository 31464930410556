import { useState, useEffect } from "react"
import Countries from "../pages/countries.json"
import * as yup from "yup"

function JoinOurCommunityFormBuilder(values) {
  const [schema, setSchema] = useState({})
  const fileSchema = yup.array().of(yup.object()).nullable()

  useEffect(() => {
    const schema = {
      full_name: {
        label: "Full Name",
        type: "text",
        required: true,
      },
      designation: {
        label: "Designation",
        type: "text",
        required: true,
      },
      company_name: {
        label: "Company Name",
        type: "text",
      },
      email: {
        label: "Email",
        type: "email",
        required: true,
      },
      phone_number: {
        label: "Phone Number",
        type: "text",
        required: true,
      },
      form_type: {
        label: "Join Indian ESG Network as",
        type: "select",
        required: true,
        options: [
          {
            value: "individual",
            label: "Individual",
          },
          {
            value: "organisation",
            label: "Organisation",
          },
        ],
      },

      bio_or_website_link: {
        label: "Share Bio / Linkedin / website link",
        type: "textarea",
      },
      resume: {
        label: "Any Related Document",
        type: "file",
        schema: fileSchema,
      },
    }

    if (values.form_type === "individual") {
      schema.areas_of_interest_for_individual = {
        label: "Area of Interest",
        type: "select",
        required: true,
        options: [
          {
            value: "Become an ESG Trainer",
            label: "Become an ESG Trainer",
          },
          {
            value: "Join the Indian ESG Network",
            label: "Join the Indian ESG Network",
          },
          {
            value:
              "Knowledge sharing (articles/blogs) through Indian ESG Network",
            label:
              "Knowledge sharing (articles/blogs) through Indian ESG Network",
          },
          {
            value: "Participate as a Speaker",
            label: "Participate as a Speaker",
          },
          {
            value: "Participate in Talk Shows",
            label: "Participate in Talk Shows",
          },
          {
            value: "Others",
            label: "Others",
          },
        ],
      }
    }

    if (values.form_type === "organisation") {
      schema.organisation_category = {
        label: "Category of Organizations",
        type: "select",
        required: true,
        options: [
          {
            value: "Academic Institution",
            label: "Academic Institution",
          },
          {
            value: "Corporate",
            label: "Corporate",
          },
          {
            value: "MSME",
            label: "MSME",
          },
          {
            value: "NGO",
            label: "NGO",
          },
          {
            value: "Social Organization",
            label: "Social Organization",
          },
          {
            value: "ESG Implementation Partner",
            label: "ESG Implementation Partner",
          },
          {
            value: "Sustainable Solutions",
            label: "Sustainable Solutions",
          },
          {
            value: "ESG IT Solutions",
            label: "ESG IT Solutions",
          },
          {
            value: "Others",
            label: "Others",
          },
        ],
      }
      schema.areas_of_interest_for_organisation = {
        label: "Solutions required from Indian ESG Network",
        type: "select",
        options: [
          {
            value: "Join the Indian ESG Network",
            label: "Join the Indian ESG Network",
          },
          {
            value: "Participate in Events",
            label: "Participate in Events",
          },
          {
            value: "Participate in Talk Shows",
            label: "Participate in Talk Shows",
          },
          {
            value: "Share Best Practises",
            label: "Share Best Practises",
          },
          {
            value: "Sponsor Events",
            label: "Sponsor Events",
          },
          {
            value: "Exhibitor of Sustainable Products at Events",
            label: "Exhibitor of Sustainable Products at Events",
          },
          {
            value: "Others",
            label: "Others",
          },
        ],
      }
      schema.required_solutions = {
        label: "Training required from Indian ESG Network",
        type: "select",
        options: [
          {
            value:
              "Indian ESG Awareness Program in Organisation (for corporates)",
            label:
              "Indian ESG Awareness Program in Organisation (for corporates)",
          },
          {
            value:
              "Indian ESG Training for Management and Employees (for corporates)",
            label:
              "Indian ESG Training for Management and Employees (for corporates)",
          },
          {
            value:
              "Training on Social Impact Assessment (for NGO/Social organization)",
            label:
              "Training on Social Impact Assessment (for NGO/Social organization)",
          },
          {
            value: "Training on Social Audits (for NGO/Social organization)",
            label: "Training on Social Audits (for NGO/Social organization)",
          },
        ],
      }
    }

    setSchema(schema)
  }, [values])

  return schema
}

export default JoinOurCommunityFormBuilder
